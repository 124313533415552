import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const FamilyQuiz = loadable(() => import('/src/components/Complex/FamilyQuiz'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const Quiz = ({ backgroundColour, utm }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            quiz {
                                fieldGroupName
                                quiz {
                                    addTitleArea
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        const componentData = node.globalBlocks.quiz.quiz

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title,
            description: componentData.description
        }

        return (
            <Section key={index} variant={backgroundColour} titleArea={titleArea} className={'c-quiz'}>
                <FamilyQuiz utm={utm} />
            </Section>
        )
    })
}

Quiz.propTypes = {
    /**
     * Data
     */
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

Quiz.defaultProps = {
    backgroundColour: 'gradient'
}

export default Quiz

export const query = graphql`
    fragment QuizForPageBuilder on WpPage_Acfpagebuilder_Layouts_Quiz {
        fieldGroupName
        backgroundColour
    }
`
